<template>
  <FullPageModal ref="CompanyFullPageModal"
    title="Select Company">
    <messages />
    <template v-slot:body>
      <b-row>
        <b-col sm="12" md="6" align-self="end">
          <div class="search-sm d-inline-block mb-2">
            <b-input :placeholder="$t('menu.search')" @input="(val) => _.debounce(searchChange(val), 500)" />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div v-if="selectedCompany == null" class="text-right">
            <p style="margin: 0px; font-size: 1rem;">You currently do not have a selected company</p>
          </div>
          <div v-else class="modal-table-selected-option-container">
            <SelectedItemTag :itemID="selectedCompany.companyID" @remove-clicked="selectedCompany = null">
              <template v-slot:tag-label>
                {{ selectedCompany.companyID }} | {{ selectedCompany.companyLegalName }}
              </template>
            </SelectedItemTag>
          </div>
        </b-col>
      </b-row>
      <b-row style="margin-top: 10px;">
        <b-col>
          <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="0.85">
            <b-card>
              <vuetable
                ref="vuetable"
                track-by="companyID"
                :api-url="apiBase"
                :query-params="makeQueryParams"
                :per-page="perPage"
                :reactive-api-url="false"
                :fields="fields"
                :css="css.table"
                pagination-path
                @vuetable:pagination-data="onPaginationData"
                @vuetable:row-clicked="rowClicked"
                @vuetable:load-error="handleLoadError"
                @vuetable:loading="onVuetableLoading"
                @vuetable:loaded="onVuetableLoaded">
              </vuetable>
            </b-card>
            <vuetable-pagination-bootstrap
              class="mt-4"
              ref="pagination"
              @vuetable-pagination:change-page="onChangePage"/>
          </b-overlay>
        </b-col>
      </b-row>
    </template>

    <template v-slot:footer>
      <b-row align-h="center">
        <b-col sm="12" md="4" xl="3">
          <b-button
            block
            variant="danger"
            size="lg"
            class="mt-4"
            @click="cancel()">
            {{ $t("forms.cancel") }}
          </b-button>
        </b-col>
        <b-col sm="12" md="4"  xl="3">
          <b-button
            block
            :disabled="selectedCompany == null"
            variant="success"
            size="lg"
            class="mt-4"
            @click="save()">
            {{ $t("forms.save") }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </FullPageModal>
</template>
<script>
import FullPageModal from '../Common/FullPageModal.vue'
import SelectedItemTag from '../Form/SelectedItemTag.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../Common/VuetablePaginationBootstrap'

import ModalMessages from '../../containers/navs/ModalMessages.vue'

import { BeaconapiUrl } from '../../constants/config'
import CssTableConfig from '../../constants/VuetableBootstrapconfig'

import { mapMutations } from 'vuex'

export default {
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    FullPageModal: FullPageModal,
    SelectedItemTag: SelectedItemTag,
    messages: ModalMessages
  },
  data () {
    var _idfield = 'companyID'
    return {
      selectedCompany: null,
      apiBase: BeaconapiUrl + '/api/company/table?isLead=false',
      sort: '',
      page: 1,
      perPage: 10,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      isError: false,

      fields: [
        {
          name: _idfield,
          sortField: _idfield,
          title: 'ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: 'companyLegalName',
          sortField: 'companyLegalName',
          title: 'Legal Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '30%'
        },
        {
          name: 'dbaCompanyName',
          sortField: 'dbaCompanyName',
          title: 'DBA Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '30%'
        },
        {
          name: 'locationType',
          sortField: 'locationType',
          title: 'Location Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '25%'
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['setModalDangerMessage']),

    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setModalDangerMessage(response)
    },
    rowClicked (dataItem, event) {
      // If this company is already selected, clear it
      this.selectedCompany = this.selectedCompany === dataItem ? null : dataItem
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },

    // End of Standard Vue Table methods

    show (opts = {}) {
      // Set preselected company if there is one
      if (opts.rfpCompany) {
        this.selectedCompany = JSON.parse(opts.rfpCompany)
      }

      this.saveFunction = opts.saveFunction

      // Once we set our config, we tell the popup modal to open
      this.$refs.CompanyFullPageModal.openModal()
    },
    save () {
      this.$refs.CompanyFullPageModal.closeModal()

      this.saveFunction(this.selectedCompany)
    },
    cancel () {
      this.$refs.CompanyFullPageModal.closeModal()
    }
  }
}
</script>
