<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'
import { instance } from '../../src/request/app-axios.js'

export default {
  data () {
    return {
      statusCode: null,
      requestForProposal: {},
      rfpPrograms: []
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getRequestForProposal (requestForProposalID) {
      return await axios
        .get(`${BeaconapiUrl}/api/request-for-proposal/${requestForProposalID}`)
        .then((result) => {
          return result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getProgramsForRFP (requestForProposalID) {
      await axios
        .get(`${BeaconapiUrl}/api/request-for-proposal/program/${requestForProposalID}`)
        .then((result) => {
          this.rfpPrograms = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async createRequestForProposal (requestForProposal) {
      requestForProposal.active = true
      await instance
        .post(`${BeaconapiUrl}/api/request-for-proposal`, requestForProposal)
        .then((result) => {
          this.setInfoMessage(result.data)
          this.statusCode = result.status
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async saveProgramsForRFP (requestForProposalID, programs) {
      await axios
        .post(`${BeaconapiUrl}/api/request-for-proposal/program/${requestForProposalID}`, programs)
        .then((result) => {
          this.statusCode = result.status
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async saveContactsForRFP (requestForProposalID, contacts) {
      await axios
        .post(`${BeaconapiUrl}/api/request-for-proposal/contact/${requestForProposalID}`, contacts)
        .then((result) => {
          this.statusCode = result.status
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async updateRequestForProposal (requestForProposal) {
      requestForProposal.lastModifiedByID = localStorage.getItem('employeeID')
      await axios
        .put(`${BeaconapiUrl}/api/request-for-proposal`, requestForProposal)
        .then((result) => {
          this.setInfoMessage(result.data)
          this.statusCode = result.status
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async activateRequestForProposals (requestForProposals) {
      await axios
        .put(`${BeaconapiUrl}/api/request-for-proposal/activate/`, requestForProposals)
        .then((result) => {
          this.statusCode = result.status
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    }
  }
}
</script>
