<template>
  <div>
    <ActivityModal ref="ActivityModal"></ActivityModal>
    <RequestForProposalContactModal ref="RequestForProposalContactModal"></RequestForProposalContactModal>
    <RequestForProposalCompanyModal ref="RequestForProposalCompanyModal"></RequestForProposalCompanyModal>
    <alert ref="Alert"></alert>
    <b-row>
      <b-col lg="12" xl="12">
        <h1>{{ title }}</h1>
        <b-card class="mb-4">
          <b-form
            @submit.stop.prevent="onValidateRFPFormSubmit"
            class="av-tooltip tooltip-label-top">
            <b-row>
              <b-col sm="12" lg="6">
                <b-card style="margin-top:10px">
                  <b-row>
                    <b-col sm="12" lg="6">
                      <h4 style="margin-bottom:25px">Company</h4>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <b-button
                        style="margin-bottom:25px"
                        block
                        variant="primary"
                        size="md"
                        @click="openCompanyModal()">
                        Select Company
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group class="readonly-input-field"
                        :style="{ 'margin-bottom': 0, 'border-color': $v.requestForProposal.company.$error ? '#dc3545' : '' }">
                        <p style="margin-bottom:0; white-space:pre-wrap;">
                          {{ companyInfo }}
                        </p>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="this.requestForProposal.company">
                    <b-col sm="6" md="6" xl="6">
                      <b-button
                        variant="warning"
                        class="mt-4"
                        block
                        size="md" @click="addActivity()">
                        {{ $t("add-activity") }}
                      </b-button>
                    </b-col>
                    <b-col sm="6" md="6" xl="6">
                      <b-button
                        variant="info"
                        class="mt-4"
                        block
                        size="md" @click="viewCompany()">
                        {{ $t("button.view-company") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>

              <b-col sm="12" lg="6">
                <b-card style="margin-top:10px">
                  <b-row>
                    <b-col sm="12" lg="6">
                      <h4 style="margin-bottom:25px">Contacts</h4>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <b-button
                        style="margin-bottom:25px"
                        block
                        variant="primary"
                        size="md"
                        @click="openContactModal()">
                        Select Contact
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group class="readonly-input-field"
                        :style="{ 'margin-bottom': 0, 'border-color': $v.requestForProposal.contacts.$error ? '#dc3545' : '' }">
                        <p style="margin-bottom:0;">
                          {{ rfpContactsList }}
                        </p>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row style="margin-top:20px;">
              <b-col>
                <b-form-group label="Status">
                  <b-form-input
                    v-model="requestForProposal.requestForProposalStatus"
                    :state="!$v.requestForProposal.requestForProposalStatus.$error"
                    @input="$v.requestForProposal.requestForProposalStatus.$touch" />

                  <div class="text-danger" v-if="!$v.requestForProposal.requestForProposalStatus.required">Field is required</div>
                  <div class="text-danger" v-if="!$v.requestForProposal.requestForProposalStatus.minLength || !$v.requestForProposal.requestForProposalStatus.maxLength">Must be between {{$v.requestForProposal.requestForProposalStatus.$params.minLength.min}} and {{$v.requestForProposal.requestForProposalStatus.$params.maxLength.max}} digits.</div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Probability">
                  <b-form-input id="rfp-probability"
                    v-model="probabilityMask"
                    :state="!$v.requestForProposal.probability.$error"
                    @input="$v.requestForProposal.probability.$touch"
                    @keyup="applyProbabilityMaskWhileTyping('rfp-probability')"
                    @keydown.backspace="triggerProbabilityBackspace" />

                  <div class="text-danger" v-if="!$v.requestForProposal.probability.required">Field is required</div>
                  <div class="text-danger" v-if="!$v.requestForProposal.probability.numeric">Must be a number</div>
                  <div class="text-danger" v-if="!$v.requestForProposal.probability.between">Must be between {{$v.requestForProposal.probability.$params.between.min}} and {{$v.requestForProposal.probability.$params.between.max}}.</div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Revenue Estimate">
                  <b-form-input id="rfp-revenue-estimate"
                    v-model="revenueEstimateMask"
                    :state="!$v.requestForProposal.revenueEstimate.$error"
                    @input="$v.requestForProposal.revenueEstimate.$touch"
                    @keyup="applyRevenueMaskWhileTyping('rfp-revenue-estimate')" />

                  <div class="text-danger" v-if="!$v.requestForProposal.revenueEstimate.required">Field is required</div>
                  <div class="text-danger" v-if="!$v.requestForProposal.revenueEstimate.numeric">Must be a number</div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Due Date">
                  <b-form-input
                    type="date"
                    v-model="requestForProposal.dueDate"
                    :state="!$v.requestForProposal.dueDate.$error"
                    @input="$v.requestForProposal.dueDate.$touch" />

                  <div class="text-danger" v-if="!$v.requestForProposal.dueDate.required">Field is required</div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Assigned Employee">
                  <multiselect
                    v-model="requestForProposal.assignedEmployee"
                    :options="employees"
                    :close-on-select="true"
                    placeholder="Select an employee"
                    track-by="employeeID"
                    label="displayName"
                    :class="{ 'multiselect-is-invalid' : $v.requestForProposal.assignedEmployee.$error }"
                    @input="$v.requestForProposal.assignedEmployee.$touch"></multiselect>

                  <div class="text-danger" v-if="!$v.requestForProposal.assignedEmployee.required">Field is required</div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Programs">
                  <multiselect
                    v-model="requestForProposal.programs"
                    :options="groupedPrograms"
                    :multiple="true"
                    group-values="options"
                    group-label="label"
                    track-by="programID"
                    label="dropdownLabel"
                    :class="{ 'multiselect-is-invalid' : $v.requestForProposal.programs.$error }"
                    placeholder="Select programs"
                    :close-on-select="false"
                    @input="$v.requestForProposal.programs.$touch"
                    ></multiselect>
                  <div class="text-danger" v-if="!$v.requestForProposal.programs.required">Field is required</div>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row align-h="left">
              <b-col sm="6" xl="2" lg="4">
                <b-button
                  block
                  variant="danger"
                  class="mt-4"
                  size="lg"
                  @click="cancelRFPForm()"
                  >{{ $t("forms.cancel") }}
                </b-button>
              </b-col>
              <b-col  lg="4" xl="2">
                <api-button-overlay :processing="saveLoading"
                  overlayVariant="success">
                  <b-button
                    block
                    type="submit"
                    variant="success"
                    class="mt-4"
                    size="lg">
                    {{ $t("forms.submit") }}
                  </b-button>
                </api-button-overlay>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import alert from '../../../../components/Common/Alert.vue'
import { validationMixin } from 'vuelidate'
import Multiselect from 'vue-multiselect'

import RequestForProposalContactModal from '../../../../components/Modals/RequestForProposalContactModal.vue'
import RequestForProposalCompanyModal from '../../../../components/Modals/RequestForProposalCompanyModal.vue'

import RequestForProposalMixin from '../../../../mixins/RequestForProposalMixin.vue'
import ProgramMixin from '../../../../mixins/ProgramMixin.vue'
import EmployeeMixin from '../../../../mixins/EmployeeMixin.vue'
import DirtyDataMixin from '../../../../mixins/DirtyDataMixin.vue'

import { mapMutations, mapActions } from 'vuex'
import { BeaconapiUrl } from '../../../../constants/config'
import ActivityModal from '../../../../components/Modals/ActivityModal.vue'

const {
  required,
  maxLength,
  minLength,
  numeric,
  between
} = require('vuelidate/lib/validators')

export default {
  components: {
    multiselect: Multiselect,
    RequestForProposalContactModal: RequestForProposalContactModal,
    RequestForProposalCompanyModal: RequestForProposalCompanyModal,
    Alert: alert,
    ActivityModal: ActivityModal
  },
  props: {
    requestForProposalID: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      title: null,
      saveLoading: false
    }
  },
  async created () {
    if (this.requestForProposalID === 0) {
      this.title = 'Add New Request For Proposal'
      this.requestForProposal = {
        company: null,
        requestForProposalStatus: '',
        probability: '',
        revenueEstimate: '',
        dueDate: null,
        assignedEmployee: null,
        programs: [],
        contacts: []
      }
    } else {
      this.title = 'Edit Request For Proposal'
      this.requestForProposal = await this.getRequestForProposal(this.requestForProposalID)
    }
    this.initDirtyDataWatcher('requestForProposal')
    await this.getAllProgramsByType()
    await this.getAllActiveEmployees()
  },
  mixins: [validationMixin, RequestForProposalMixin, ProgramMixin, EmployeeMixin, DirtyDataMixin],
  validations: {
    requestForProposal: {
      company: {
        required
      },
      requestForProposalStatus: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100)
      },
      probability: {
        required,
        numeric,
        between: between(0, 100)
      },
      revenueEstimate: {
        required,
        numeric
      },
      dueDate: {
        required
      },
      assignedEmployee: {
        required
      },
      programs: {
        required
      },
      contacts: { }
    }
  },
  methods: {
    ...mapMutations(['setPreserveMessageAfterPush']),
    // Form methods
    validateRFPState (name) {
      const { $dirty, $error } = this.$v.requestForProposal[name]
      return $dirty ? !$error : null
    },
    async onValidateRFPFormSubmit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        await this.saveRequestForProposal(this.requestForProposal)
      }
    },
    async saveRequestForProposal (requestForProposal) {
      this.saveLoading = true

      this.requestForProposal.requestForProposalID
        ? await this.updateRequestForProposal(requestForProposal)
        : await this.createRequestForProposal(requestForProposal)

      this.saveLoading = false

      if (this.statusCode === 200) {
        this.setDirtyData(false)
        this.setPreserveMessageAfterPush(true)
        this.$router.push({ name: 'requestForProposalsTable' })
      }
    },
    cancelRFPForm () {
      this.$router.push({ name: 'requestForProposalsTable' })
    },
    openCompanyModal () {
      this.$refs.RequestForProposalCompanyModal.show({
        rfpCompany: JSON.stringify(this.requestForProposal.company),
        saveFunction: (company) => {
          if (this.requestForProposal.company != null) {
            if (this.requestForProposal.company.companyID !== company.companyID) {
              this.requestForProposal.contacts = []
            }
          }

          this.requestForProposal.company = company
        }
      })
    },
    openContactModal () {
      if (this.requestForProposal.company == null) {
        this.$refs.Alert.show({
          title: 'Please Select A Company',
          message: 'Before you can select contacts, you must select a company.',
          okButton: 'Okay'
        })

        return
      }

      this.$refs.RequestForProposalContactModal.show({
        apiUrl: `${BeaconapiUrl}/api/company-contact/table/${this.requestForProposal.company.companyID}`,
        rfpContacts: JSON.stringify(this.requestForProposal.contacts),
        saveFunction: (contacts) => {
          this.requestForProposal.contacts = contacts
        }
      })
    },
    async addActivity () {
      await this.$refs.ActivityModal.show({
        title: 'Add New Activity',
        companyID: this.requestForProposal.company.companyID,
        exitFunction: () => {
        }
      })
    },
    viewCompany () {
      var routeData = this.$router.resolve({ name: 'EditCompany', params: { companyID: this.requestForProposal.company.companyID } })
      // this will make the page open in a new tab/window
      window.open(routeData.href, '_blank')
    },

    // Masking methods
    setRevenueEstimateMask (revenueEstimate) {
      if (revenueEstimate === undefined) {
        return '$'
      }

      var x = revenueEstimate.toString().replace(/\D/g, '').replace('$', '')
      return '$' + x
    },
    setProbabilityMask (probability) {
      if (probability === undefined) {
        return '%'
      }

      var x = probability.toString().replace(/\D/g, '').replace('%', '')
      return x + '%'
    },
    applyRevenueMaskWhileTyping (inputID) {
      var inputValue = document.getElementById(inputID).value.toString()
      document.getElementById(inputID).value = this.setRevenueEstimateMask(inputValue)
    },
    applyProbabilityMaskWhileTyping (inputID) {
      var inputValue = document.getElementById(inputID).value.toString()
      document.getElementById(inputID).value = this.setProbabilityMask(inputValue)
    },
    triggerProbabilityBackspace (e) {
      if (e.target.selectionStart === this.probabilityMask.length) {
        e.target.value = e.target.value.substring(0, this.probabilityMask.length - 1)
        this.$emit('input', e.target.value)
      }
    }
  },
  computed: {
    revenueEstimateMask: {
      get: function () {
        return this.setRevenueEstimateMask(this.requestForProposal.revenueEstimate)
      },
      set: function (v) {
        this.requestForProposal.revenueEstimate = v.toString().replace(/\D/g, '').replace('$', '')
      }
    },
    probabilityMask: {
      get: function () {
        return this.setProbabilityMask(this.requestForProposal.probability)
      },
      set: function (v) {
        this.requestForProposal.probability = v.toString().replace(/\D/g, '').replace('%', '')
      }
    },
    companyInfo () {
      if (this.requestForProposal.company != null) {
        return `Company ID ${this.requestForProposal.company.companyID} \n ${this.requestForProposal.company.companyLegalName}`
      }

      return 'No company selected for this request for proposal'
    },
    rfpContactsList () {
      if (this.requestForProposal.contacts !== undefined) {
        if (this.requestForProposal.contacts.length > 0) {
          return this.requestForProposal.contacts.map(c => `${c.firstName} ${c.lastName}`).join(', ')
        }
      }

      return 'No contacts selected for this request for proposal'
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-control.is-invalid {
    border-color: #dc3545;
  }

  .multiselect-is-invalid ::v-deep .multiselect__tags {
    border: 1px solid #dc3545 !important;
  }
</style>
