<template>
  <full-page-modal ref="ContactFullPageModal"
    title="Select Contacts">
    <template v-slot:body>
      <b-row style="padding: 7px 0px;">
        <b-col sm="12" md="6" align-self="end">
          <div class="search-sm d-inline-block mb-2">
            <b-input :placeholder="$t('menu.search')" @input="(val) => _.debounce(searchChange(val), 500)" />
          </div>
        </b-col>
        <b-col sm="12" md="6" align-self="center">
          <div v-if="selectedContacts.length == 0" class="text-right">
            <p style="margin: 0px; font-size: 1rem;">You currently do not have any selected contacts</p>
          </div>
          <div v-else class="modal-table-selected-option-container">
            <div v-for="contact in selectedContacts" :key="contact.contactID">
              <selected-item-tag :itemID="contact.contactID" @remove-clicked="(itemID) => removeSelection(itemID)">
                <template v-slot:tag-label>
                  {{ contact.contactID }} | {{ contact.firstName }} {{ contact.lastName }}
                </template>
              </selected-item-tag>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="0.85">
            <b-card>
              <vuetable
                ref="vuetable"
                track-by="contactID"
                :api-url="apiBase"
                :query-params="makeQueryParams"
                :per-page="perPage"
                :reactive-api-url="false"
                :fields="fields"
                :css="css.table"
                pagination-path
                @vuetable:pagination-data="onPaginationData"
                @vuetable:row-clicked="rowClicked"
                @vuetable:load-error="handleLoadError"
                @vuetable:loading="onVuetableLoading"
                @vuetable:loaded="onVuetableLoaded">
                <template slot="contactName" slot-scope="props">
                  <p class="text-muted">{{ props.rowData.firstName }}, {{ props.rowData.lastName }}</p>
                </template>
              </vuetable>
            </b-card>
            <vuetable-pagination-bootstrap
              class="mt-4"
              ref="pagination"
              @vuetable-pagination:change-page="onChangePage"/>
          </b-overlay>
        </b-col>
      </b-row>
    </template>

    <template v-slot:footer>
      <b-row>
        <b-col sm="12" md="4" xl="3">
          <b-button
            block
            variant="danger"
            size="lg"
            class="mt-4"
            @click="cancel()">
            {{ $t("forms.cancel") }}
          </b-button>
        </b-col>
        <b-col sm="12" md="4"  xl="3">
          <b-button
            block
            variant="success"
            size="lg"
            class="mt-4"
            @click="save()">
            {{ $t("forms.save") }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </full-page-modal>
</template>
<script>
import fullPageModal from '../Common/FullPageModal.vue'
import SelectedItemTag from '../Form/SelectedItemTag.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../Common/VuetablePaginationBootstrap'

import CssTableConfig from '../../constants/VuetableBootstrapconfig'

export default {
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'full-page-modal': fullPageModal,
    'selected-item-tag': SelectedItemTag
  },
  data () {
    var _idfield = 'contactID'
    return {
      rfpContacts: [],
      selectedContacts: [],
      apiBase: '',
      sort: '',
      page: 1,
      perPage: 10,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      isError: false,

      fields: [
        {
          name: _idfield,
          sortField: _idfield,
          title: 'ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: '__slot:contactName',
          sortField: 'contactName',
          title: 'Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '42.5%'
        },
        {
          name: 'emailAddress',
          sortField: 'emailAddress',
          title: 'Email',
          titleClass: '',
          dataClass: 'text-muted',
          width: '42.5%'
        }
      ]
    }
  },
  methods: {

    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    rowClicked (dataItem, event) {
      if (this.selectedContacts.filter(c => c.contactID === dataItem.contactID).length > 0) {
        this.selectedContacts = this.selectedContacts.filter(c => c.contactID !== dataItem.contactID)
      } else {
        this.selectedContacts.push(dataItem)
      }
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },

    // End of Standard Vue Table methods

    show (opts = {}) {
      this.apiBase = opts.apiUrl

      this.selectedContacts = JSON.parse(opts.rfpContacts)

      this.saveFunction = opts.saveFunction

      // Once we set our config, we tell the popup modal to open
      this.$refs.ContactFullPageModal.openModal()
    },
    save () {
      this.$refs.ContactFullPageModal.closeModal()

      this.saveFunction(this.selectedContacts)
    },
    cancel () {
      this.$refs.ContactFullPageModal.closeModal()
    },
    removeSelection (contactID) {
      this.selectedContacts = this.selectedContacts.filter(c => c.contactID !== contactID)
    }
  }
}
</script>
